<template>
  <div class="container">
    <div class="row justify-content-center mt-4 mb-4">
      <div class="col-sm-12 col-lg-6">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
        <div class="wet-form">
          <label class="form-label" for="titulo-espanol">Título</label>
          <input
            class="form-control"
            type="text"
            id="titulo-espanol"
            v-model="tituloEspanol"
          />
        </div>
        <div class="wet-form">
          <label class="form-label" for="subtitulo-espanol">Subtítulo</label>
          <input
            class="form-control"
            type="text"
            id="subtitulo-espanol"
            v-model="subtituloEspanol"
          />
        </div>
        <div class="wet-form">
          <label class="form-label" for="descripcion-espanol"
            >Descripción</label
          >
          <textarea
            class="form-control"
            type=""
            rows="3"
            id="descripcion-espanol"
            v-model="descripcionEspanol"
          />
        </div>
        <button class="btn btn-success btn-lg mt-3" @click="syncWords('ES')">
          Guardar
        </button>
      </div>
      <div class="banner-container col-sm-12 col-lg-6">
        <img class="banner-img" src="../../assets/banner.webp" alt="" />
        <div class="__banner-content">
          <div class="content-title">
            <h1 class="banner-title">{{ tituloEspanol }}</h1>
            <h2>{{ subtituloEspanol }}</h2>
          </div>
          <p>{{ descripcionEspanol }}</p>
        </div>
      </div>
    </div>
    <hr class="pill mb-4" />
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
        <div class="wet-form">
          <label class="form-label" for="titulo-ingles">Title</label>
          <input
            class="form-control"
            type="text"
            id="titulo-ingles"
            v-model="tituloIngles"
          />
        </div>
        <div class="wet-form">
          <label class="form-label" for="subtitulo-ingles">Subtitle</label>
          <input
            class="form-control"
            type="text"
            id="subtitulo-ingles"
            v-model="subtituloIngles"
          />
        </div>
        <div class="wet-form">
          <label class="form-label" for="descripcion-ingles">Description</label>
          <textarea
            class="form-control"
            type=""
            rows="3"
            id="descripcion-ingles"
            v-model="descripcionIngles"
          />
        </div>
        <button
          class="btn btn-success btn-lg mt-3"
          @click="syncWords('english')"
        >
          Save
        </button>
      </div>
      <div class="banner-container col-sm-12 col-lg-6">
        <img class="banner-img" src="../../assets/banner.webp" alt="" />
        <div class="__banner-content">
          <div class="content-title">
            <h1 class="banner-title">{{ tituloIngles }}</h1>
            <h2>{{ subtituloIngles }}</h2>
          </div>
          <p>{{ descripcionIngles }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";
import store from "../../store";

export default {
  name: "Banner",
  data() {
    return {
      actionData: {},
      tituloIngles: "Wetforest",
      subtituloIngles: "Ventures",
      descripcionIngles:
        "Plataforma de negocios para impactar el futuro de emprendimientos y empresas en tiempos de grandes cambios.",
      sectionEs: "",
      tituloEspanol: "Wetforest",
      subtituloEspanol: "Ventures",
      descripcionEspanol:
        "Plataforma de negocios para impactar el futuro de emprendimientos y empresas en tiempos de grandes cambios.",
      sectionEn: "",
    };
  },
  methods: {
    goToRegister() {
      this.$router.push("/register");
    },
    async fetchData() {
      if (store.state.sections.floatSection) {
        this.actionData = { ...store.state.sections.floatSection.components };
      }
      if (store.state.sections.banner) {
        this.titulo = store.state.sections.banner.components.title;
        this.subtitulo = store.state.sections.banner.components.subtitle;
        this.descripcion = store.state.sections.banner.components.text;
      }
    },
    async fetchWords() {
      const section = await getSection("banner");
      const sectionEs = section.filter((section) => section.language == "ES");
      const sectionEn = section.filter((section) => section.language == "EN");
      this.sectionEs = sectionEs;
      this.sectionEn = sectionEn;
      this.tituloEspanol = sectionEs[0].components.title;
      this.subtituloEspanol = sectionEs[0].components.subtitle;
      this.descripcionEspanol = sectionEs[0].components.text;
      this.tituloIngles = sectionEn[0].components.title;
      this.subtituloIngles = sectionEn[0].components.subtitle;
      this.descripcionIngles = sectionEn[0].components.text;
    },
    async syncWords(lan) {
      if (lan === "ES") {
        const words = {
          id: this.sectionEs[0]._id,
          section: "banner",
          components: {
            title: this.tituloEspanol,
            subtitle: this.subtituloEspanol,
            text: this.descripcionEspanol,
          },
          language: "ES",
        };
        await putSection(words);
      } else {
        const words = {
          id: this.sectionEn[0]._id,
          section: "banner",
          components: {
            title: this.tituloIngles,
            subtitle: this.subtituloIngles,
            text: this.descripcionIngles,
          },
          language: "EN",
        };
        await putSection(words);
      }
    },
  },

  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
    this.fetchWords();
  },

  props: {
    Idioma: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner-container {
  position: relative;

  .banner-img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
  }
  .__banner-content {
    text-align: center !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  h1,
  h2,
  p {
    color: white;
  }

  h1 {
    font-family: "OpenSans-SemiBold" !important;
    font-size: 3rem !important;
  }

  h2 {
    font-family: "OpenSans-Regular" !important;
    margin-left: 6rem !important;
    font-size: 2rem !important;
    font-weight: 400 !important;
  }

  p {
    font-size: 1.2rem !important;
  }
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
</style>
